import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function Alert() {
  return (
    <div className="bg-white flex justify-center">
      <div className="font-regular relative ml-4 mr-4 block w-3/4 p-4 text-base leading-5 text-kycpurple opacity-100">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-gray-600"
              aria-hidden="true"
            />
          </div>

          <div className="ml-3">
            <p className="text-sm font-medium text-gray-600">
              Please note that many of the teachers pause classes during school
              holidays. Please check first to avoid disappointment.
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
