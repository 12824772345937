import React from "react";
//import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Newsletter from "./components/Newsletter";
import NewTimetable from "./components/NewTimetable";
import Alert from "./components/Alert";
function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Newsletter />
      <Alert />
      <NewTimetable />
      {/* <img src={logo} className="App-logo" alt="logo" /> */}
      {/*       <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a> */}
    </div>
  );
}

export default App;
