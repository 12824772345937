// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore, persistentLocalCache } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBH7vZHsFFhSnx0WZA38leqD98S0RHwxi4",
  authDomain: "katie-yoga-collective.firebaseapp.com",
  projectId: "katie-yoga-collective",
  storageBucket: "katie-yoga-collective.appspot.com",
  messagingSenderId: "724669782493",
  appId: "1:724669782493:web:3754e624176b50b8acfe34",
  measurementId: "G-Z2G2GN411X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    cacheSizeBytes: 10485760, // The maximum size in bytes for the local cache (optional)
    // Additional settings...
  }),
});

// Initialize Analytics only in production
if (process.env.NODE_ENV === "production") {
  getAnalytics(app);
}

export { db };
